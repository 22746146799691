import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import LocalImage from './LocalImage';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`We follow a quality driven mindset when applying `}<strong parentName="p">{`best practices`}</strong>{` around data analysis, transformation, and data validation.`}</p>
    </PageDescription>
    <p>{`At Tekdatum, we believe that data quality is key to unlocking the full potential of your data. Data quality is the foundation for accurate analyses, informed decision-making, and achieving your business goals. We are committed to providing you with the technologies and resources you need to improve the quality of your data.
Our team of experts has years of experience in data management and understands the challenges of ensuring data quality. We offer various services, including data profiling, data cleaning, and governance, to help you identify and address data quality issues. We also provide training and support to help you develop the skills and knowledge necessary to maintain high-quality data over the long term.
Whether you are a small business owner or a large corporation, our services can help you achieve better outcomes by improving the quality of your data. We believe that high-quality data is the foundation for success in today’s data-driven world, and we’re here to help you achieve it. Contact us today to learn more about how we can help you improve the quality of your data and achieve your business goals.`}</p>
    <h2>{`Services`}</h2>
    <ul>
      <li parentName="ul">{`Data profiling`}</li>
      <li parentName="ul">{`Data cleaning`}</li>
      <li parentName="ul">{`Data governance`}</li>
      <li parentName="ul">{`Customized A.I. roadmap development`}</li>
      <li parentName="ul">{`Data infrastructure and Architecture - hardware and software`}</li>
      <li parentName="ul">{`Data acquisition strategy`}</li>
      <li parentName="ul">{`Identify data needs to meet the company’s business objectives`}</li>
      <li parentName="ul">{`Bridge communication between IT and business `}</li>
      <li parentName="ul">{`Team characterization`}</li>
      <li parentName="ul">{`Business-driven model creation`}</li>
    </ul>
    <h2>{`Technologies`}</h2>
    <p>{`We provide a  proprietary data quality technology to clean and detect anomalous points.`}</p>
    <Row className="technology-list" mdxType="Row">
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://www.ray.io/"} imageSRC={'/images/tools/ray_56.png'} altTxt={"Ray"} mdxType="LocalImage" />
        <h3>
  Ray.io 
  <span>Makes it easy to scale AI and Python workloads — from reinforcement learning to deep learning to tuning, and model serving.</span>
        </h3>
      </Column>
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://www.dask.org/"} imageSRC={'/images/tools/dask_56.png'} altTxt={"Dask"} mdxType="LocalImage" />
        <h3>
  Dask 
  <span>Library to scale natively, python packages and ecosystems</span>
        </h3>
      </Column>
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://github.com/cleanlab/cleanlab"} imageSRC={'/images/tools/cleanLab_56.png'} altTxt={"Clean Lab"} mdxType="LocalImage" />
        <h3>
  Clean Lab 
  <span>Provide algorithms/interfaces to improve datasets and diagnose/fix issues in them</span>
        </h3>
      </Column>
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://openrefine.org/"} imageSRC={'/images/tools/openrefine_56.png'} altTxt={"Open Refine"} mdxType="LocalImage" />
        <h3>
  Open Refine 
  <span>Library for cleaning and transforming messy data</span>
        </h3>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      